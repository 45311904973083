(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory();
	else if(typeof define === 'function' && define.amd)
		define("GPTTokenizer_o200k_base", [], factory);
	else if(typeof exports === 'object')
		exports["GPTTokenizer_o200k_base"] = factory();
	else
		root["GPTTokenizer_o200k_base"] = factory();
})(globalThis, () => {
return 